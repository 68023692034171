import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import { formattedTimeWithTimezone } from '@utils/dateUtils'
import pluralize from '@lib/pluralize'
import ButtonCta from '@components/atoms/button-cta'
import { flags, useFeatureFlags } from '@lib/feature-flags'
import {
  IconPrice,
  IconCalendar,
} from '@components/atoms/icons'
import IconFlagFilled from '@components/atoms/icons/icon-flag-filled'
import { centsToDollarsFormatted } from '@lib/conversions'
import { composeOptimizedImageUrl } from '@utils/composeOptimizedImageUrl'
import useStyles, { CardIcon } from './group-event-card-large.styles'

const GroupEventCardLarge = ({
  event, logoImageUrl, dataAttrs, openInNewTab,
}) => {
  const classes = useStyles()
  const {
    title,
    tags = [],
    startDateTime,
    endDateTime,
    slug,
    maxAttendance,
    registrationCount,
    featuredImageUrl,
    priceInCents,
    sessions,
    coachFacility,
    registrationFull,
    hasWaitlist,
  } = event

  const timePeriod = formattedTimeWithTimezone(startDateTime, endDateTime, coachFacility?.timezone)
  const priceInDollars = centsToDollarsFormatted(priceInCents)
  const remainingSpots = maxAttendance - registrationCount

  const isAdultsOnly = tags.includes('Adults Only') ? 'Adults Only' : null
  const isJuniorsOnly = tags.includes('Juniors Only') ? 'Juniors Only' : null
  const ageSpecificGroup = isAdultsOnly || isJuniorsOnly
  const [isAgeSpecificGroupEnabled] = useFeatureFlags([flags.FLAG_AGE_SPECIFIC_GROUP])

  const showWaitlistChip = registrationFull && hasWaitlist
  let limitedAvailabilityEventFullChipText = registrationFull ? 'Event Full' : ''
  limitedAvailabilityEventFullChipText = showWaitlistChip ? 'Waitlist' : limitedAvailabilityEventFullChipText
  if (maxAttendance !== null && remainingSpots <= 5 && !registrationFull) {
    limitedAvailabilityEventFullChipText = 'Limited Availability'
  }

  const standardLogoImageUrl = slug.startsWith('pga-camps-') ? '/images/pga-camps-logo.png' : '/images/logo-blue.png'

  return (
    <Card className={`${classes.groupEvent}`} {...dataAttrs}>
      <Link href={`/things-to-do/events/${slug}`}>
        <a style={{ display: 'contents' }} target={openInNewTab ? '_blank' : '_self'}>
          <CardMedia
            className={`${classes.media}`}
            image={featuredImageUrl && composeOptimizedImageUrl(featuredImageUrl, 900)}
            title={title}
          />

          {(logoImageUrl || standardLogoImageUrl) && (
            <CardIcon
              background={logoImageUrl || standardLogoImageUrl}
              data-testid="organization"
            />
          )}
          <Box className={classes.chipsWrapper}>
            {limitedAvailabilityEventFullChipText && (
              <Chip
                className={classes.chip}
                label={limitedAvailabilityEventFullChipText}
              />
            )}
            {isAgeSpecificGroupEnabled && ageSpecificGroup && (
              <Chip className={classes.chip} label={ageSpecificGroup} />
            )}
          </Box>
          <Box
            style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}
          >
            <CardContent className={classes.cardContent}>
              <Box>
                {startDateTime && endDateTime
            && (
              <Typography variant="caption" className={classes.caption}>
                {timePeriod}
              </Typography>
            )
                }
                <Typography variant="h5" color="secondary" className={classes.cardSubtitle}>{title}</Typography>
              </Box>
              <Box>
                {coachFacility != null && (
                  <Box display="flex" mb={1}>
                    <IconFlagFilled />
                    <Typography variant="caption" className={classes.caption} style={{ marginLeft: '12px' }}>
                      {coachFacility.name}
                    </Typography>
                  </Box>
                )}
                {sessions?.length > 0 && (
                  <Box display="flex" mb={1}>
                    <IconCalendar />
                    <Typography variant="caption" className={classes.caption} style={{ marginLeft: '12px' }}>
                      {sessions.length} {pluralize(sessions.length, 'session')}
                    </Typography>
                  </Box>
                )}
                {priceInCents != null && (
                  <Box display="flex">
                    <IconPrice />
                    <Typography variant="caption" className={classes.caption} style={{ marginLeft: '12px' }}>
                      {priceInDollars} / participant
                    </Typography>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Box>
        </a>
      </Link>
      <ButtonCta
        variant="text"
        arrow
        {...(!openInNewTab && { href: `/things-to-do/events/${slug}` })}
        {...(openInNewTab && { externalLink: `/things-to-do/events/${slug}` })}
        className={classes.ctaButton}
        dataAttrs={{ ...dataAttrs }}
      >
        View Event
      </ButtonCta>
    </Card>
  )
}

GroupEventCardLarge.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
    featuredImageUrl: PropTypes.string,
    startDateTime: PropTypes.string,
    endDateTime: PropTypes.string,
    maxAttendance: PropTypes.number,
    slug: PropTypes.string,
    registrationCount: PropTypes.number,
    priceInCents: PropTypes.number,
    sessions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
    coachFacility: PropTypes.shape({
      name: PropTypes.string,
      timezone: PropTypes.string,
      id: PropTypes.string,
    }),
    registrationFull: PropTypes.bool,
    hasWaitlist: PropTypes.bool,
  }).isRequired,
  logoImageUrl: PropTypes.string,
  dataAttrs: PropTypes.object,
  openInNewTab: PropTypes.bool,
}

export default GroupEventCardLarge
