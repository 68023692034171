/* reorderArray util helps to reorder slides from
* 1 3 5  7 9  11
* 2 4 6  8 10 12
* to
 1 2 3  7  8  9
 4 5 6  10 11 12
 that could be used for working with https://react-slick.neostack.com/ with multiple rows
*  */
const reorderArray = (arr, rows) => {
  if (arr.length < 7 || rows !== 2) return arr
  const result = []
  const groups = []
  const groupSize = 6

  for (let i = 0; i < arr.length; i += groupSize) {
    groups.push(arr.slice(i, i + groupSize))
  }

  groups.forEach((group) => {
    let firstHalf = []
    let secondHalf = []
    firstHalf = group.slice(0, Math.ceil(group.length / 2))
    secondHalf = group.slice(Math.ceil(group.length / 2))

    firstHalf.forEach((item, index) => {
      result.push(item)
      result.push(secondHalf[index])
    })
  })
  return result.filter(item => item != null)
}

export { reorderArray }
