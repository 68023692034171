import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  offeringsRoot: {
    margin: '0 auto',
    paddingTop: '3rem',
  },
  offeringsOverline: {
    color: theme.palette.primary.dark,
    fontSize: '12px',
    fontFamily: 'Nunito Sans',
    letterSpacing: '1px',
  },
  paginationNumber: {
    fontFamily: 'Source Serif Pro',
    fontSize: '18px',
    fontWeight: '700',
    letterSpacing: '0.02em',
    textAlign: 'center',
    textDecoration: 'none',
    color: theme.palette.grey.dark,
    padding: theme.spacing(1),
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
  },
  eventsWrapper: {
    paddingBottom: '64px',
    '& .slick-slide': {
      padding: '30px 0px',
      '& > div': {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      '& > div:first-child': {
        paddingBottom: '28px',
      },
    },
    '& .slick-list': {
      margin: '0 auto',
      width: 'calc( 100% + 28px)',
      marginLeft: '-14px',
    },
    '& .slick-dots': {
      bottom: '24px',
    },
    '& .slick-dots li': {
      width: '40px',
      height: '40px',
      margin: '0',
    },
    '& .slick-active > a': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.contrastText,
    },
    '& .slick-next': {
      display: 'none!important',
    },
    '& .slick-prev': {
      display: 'none!important',
    },
  },
  eventsWrapperCenter: {
    '& .slick-slide:first-child > div': {
      float: 'right',
    },
    '& .slick-slide > div': {
      width: '410px',
    },
  },
  singleEventWrapperCenter: {
    '& .slick-slide > div': {
      width: '410px',
      margin: '0 auto',
    },
  },
}))

export default useStyles
