export const composeOptimizedImageUrl = (url, width) => {
  if (!url) return ''
  if (!width) return url

  // do not optimize image loading with existing height query
  if (url.includes('?h=') || url.includes('&h=') || url.includes('/h_')) {
    return url
  }

  const allowedHosts = [
    'images.ctfassets.net',
    'images.unsplash.com',
    'res.cloudinary.com',
  ]
  const parsedUrl = new URL(url)
  const { host } = parsedUrl

  if (allowedHosts.includes(host)) {
    if (host === 'res.cloudinary.com' && url.includes('/pgahq/image/upload')) {
      const [part1, part2] = url.split('upload')
      return `${part1}upload/w_${width}${part2}`
    }
    return `${url}&w=${width}`
  }
  return url
}
