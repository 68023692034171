export const milesToMeters = miles => miles * 1609

export const metersToMiles = meters => meters / 1609

export const centsToDollars = amountInCents => (amountInCents != null ? +(amountInCents / 100).toFixed(2) : null)

export const centsToDollarsFormatted = amountInCents => (amountInCents != null
  ? `$${
    centsToDollars(amountInCents).toLocaleString('en-US', {
      minimumFractionDigits: 2,
    })}`
  : null)
