import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import SliderNew from '@components/slider-new'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import useStyles from './coach-offering-group-events.styles'
import { reorderArray } from './utils'

const EventsSlider = ({ events, renderEvent, rows }) => {
  const eventsLength = events.length
  const classes = useStyles()
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: Math.min(eventsLength, 3),
    slidesToScroll: 3,
    initialSlide: 0,
    rows: (eventsLength > 6 && rows) || 1,
    mode: 'snap',
    customPaging(i) {
      return (
        <a className={classes.paginationNumber}>
          {i < 9 && 0}{i + 1}
        </a>
      )
    },
    responsive: isClient ? [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: Math.min(eventsLength, 2),
          slidesToScroll: 2,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
    ] : null,
  }

  const config = {
    initial: 0,
    loop: false,
    mode: 'snap',
    slides: { origin: 'center', perView: 1.1, spacing: 10 },
    breakpoints: {
      '(min-width: 600px)': {
        loop: false,
        slides: { perView: 'auto', spacing: 28 },
      },
    },
  }

  const reorderedEvents = reorderArray(events, rows)

  return (
    <>
      <Hidden implementation="css" mdUp>
        <SliderNew
          className={classes.slider}
          controlClassName={classes.customControl}
          config={config}
        >
          {events.map(event => (
            renderEvent(event)
          ))}
        </SliderNew>
      </Hidden>
      <Hidden implementation="css" smDown>
        <Grid>
          {eventsLength < 4
            ? (
              <Grid container spacing={4} style={{ marginTop: '1rem', marginBottom: '2rem' }}>
                {events.map(event => (
                  <Grid
                    key={event.slug}
                    item
                    md={4}
                  >
                    {renderEvent(event)}
                  </Grid>
                ))}
              </Grid>
            )
            : (
              <Slider
                {...settings}
                className={`${classes.eventsWrapper} ${eventsLength === 2 && classes.eventsWrapperCenter} ${eventsLength === 1 && classes.singleEventWrapperCenter}`}
              >
                {(reorderedEvents.map(event => (
                  renderEvent(event)
                )))}
              </Slider>
            )}
        </Grid>
      </Hidden>
    </>
  )
}

EventsSlider.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  renderEvent: PropTypes.func.isRequired,
  rows: PropTypes.number,
}

export default EventsSlider
