import { DateTime, Interval } from 'luxon'

const MINUTES_IN_HOUR = 60
const SECONDS_IN_MINUTE = MINUTES_IN_HOUR

export const getTimePeriod = (startDateTime, endDateTime) => `${DateTime.fromISO(startDateTime).toFormat('h:mm')} - ${DateTime.fromISO(endDateTime).toFormat('t')}`
export const getTimePeriodWithTimezone = (startDateTime, endDateTime, timezone) => `${DateTime.fromISO(startDateTime).setZone(timezone).toFormat('h:mm a')} - ${DateTime.fromISO(endDateTime).setZone(timezone).toFormat('t (ZZZZ)')}`

// Renders Date & Time range if start/end are on same day
// Tue, Jul 20 • 8:00 - 12:00 AM
// Render Date range (without time) if across days
// Tue, Jul 20 - Fri, Jul 23
export const formattedTime = (startDateTime, endDateTime) => {
  const period = Interval.fromISO(`${startDateTime}/${endDateTime}`)

  return (
    <time dateTime={period.toISO()}>
      {
        period.hasSame('day')
          ? `${period.start.toFormat('EEE, MMM dd \u2022 h:mm')} - ${period.end.toFormat('t')}`
          : `${period.toFormat('EEE, MMM dd')}`
      }
    </time>
  )
}

export const formattedTimeWithTimezone = (startDateTime, endDateTime, timezone) => {
  const period = Interval.fromISO(`${startDateTime}/${endDateTime}`)

  return (
    <time dateTime={period.toISO()}>
      {
        period.hasSame('day')
          ? `${period.start.setZone(timezone).toFormat('EEE, MMM dd \u2022 h:mm')} - ${period.end.setZone(timezone).toFormat('t (ZZZZ)')}`
          : `${period.toFormat('EEE, MMM dd')}`
      }
    </time>
  )
}


export const fullDayDateAndTime = (startDateTime, endDateTime) => `${DateTime.fromISO(startDateTime).toFormat('ccc, LLL d')} • ${getTimePeriod(startDateTime, endDateTime)}`
export const fullDayDateAndTimeWithTimezone = (startDateTime, endDateTime, timezone) => `${DateTime.fromISO(startDateTime).setZone(timezone).toFormat('ccc, LLL d')} • ${getTimePeriodWithTimezone(startDateTime, endDateTime, timezone)}`

export const getDate = startDateTime => `${DateTime.fromISO(startDateTime).toFormat('cccc, LLLL d')}`
export const getTime = startDateTime => `${DateTime.fromISO(startDateTime).toFormat('h:mm a')}`
export const getShortDate = (startDateTime, timezone) => `${DateTime.fromISO(startDateTime).setZone(timezone).toFormat('ccc, LLL d')}`
export const getTimeWithTimeZone = (startDateTime, endDateTime, timezone) => `${getTimePeriodWithTimezone(startDateTime, endDateTime, timezone)}`

export const getDeltaString = (minutesAgo, updatedDate) => {
  let date = updatedDate

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const dateFormatted = date.toLocaleDateString('en-US', dateOptions)

  if (minutesAgo < MINUTES_IN_HOUR) date = `${minutesAgo} minutes ago`
  if (minutesAgo <= 1) date = '1 minute ago'
  if (minutesAgo >= MINUTES_IN_HOUR) date = dateFormatted

  return date
}

export const diffInMinutes = (oldDate) => {
  return Math.ceil((new Date() - new Date(oldDate)) / (SECONDS_IN_MINUTE * 1000))
}
